import React from 'react';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import { Link } from '../../common';
import { styleVars } from '../../lib/helpers/style';

const breakpoint = styleVars.mobileBreakpoint;

const CollapsingMenu = ({ className = '', heading, links }) => (
  <Navbar expand={breakpoint} className={`${className} d-${breakpoint}-block collapsing-nav p-0`}>
    <Navbar.Brand className={`font-weight-bold d-none d-${breakpoint}-block mb-2`}>{heading}</Navbar.Brand>
    <Navbar.Toggle
      aria-controls="overview-nav"
      className={`toggle-btn w-100 d-${breakpoint}-none px-0 pt-0`}
      tabIndex="0"
    >
      <div className="button-content d-flex justify-content-between" tabIndex="-1">
        <span className="font-weight-bold">{heading}</span>
        <span className="plus-minus-toggle plus-minus-toggle-primary" />
      </div>
    </Navbar.Toggle>
    <Navbar.Collapse id="overview-nav">
      <Nav as="ul" className={`flex-column pt-4 pt-${breakpoint}-0`}>
        {links &&
          links.map(({ label, url }, i) => (
            <Nav.Item key={`collapseMenu-${i}`} as="li">
              <Nav.Link as={Link} activeClassName="active" href={url} className="px-0">
                {label}
              </Nav.Link>
            </Nav.Item>
          ))}
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default CollapsingMenu;
